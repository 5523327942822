export const getBrowserLanguage = () => {
  if (typeof navigator !== 'undefined') {
    const language = navigator.languages
      ? navigator.languages[0]
      : navigator.language;
    const languageMap: { [key: string]: string } = {
      'pt-BR': 'br',
      pt: 'pt',
      'en-US': 'en',
      en: 'en',
      'de-DE': 'de',
      de: 'de',
      'fr-FR': 'fr',
      fr: 'fr',
      'it-IT': 'it',
      it: 'it',
    };
    return languageMap[language] || 'en';
  }

  return 'en';
};
